<template>
  <div>
    <Confirm
      :confirmText="`${editForm.id ? 'Update' : 'Add'} ${display || 'Health Data'}`"
      :dialog="editFormOpened"
      :loading="loading"
      :title="`${editForm.id ? 'Edit' : 'Add'} ${display || 'Health Data'}`"
      cancelText="Close"
      maxWidth="90%"
      rootClass="monitoring-form"
      text=""
      width="600px"
      @confirm="performStore"
      @toggleDialog="toggleEditFormOpened"
    >
      <v-container class="no-padding-bottom no-padding-top">
        <v-row>
          <template v-if="editForm.showOptions">
            <v-col cols="4">
              <v-subheader>Type</v-subheader>
            </v-col>
            <v-col cols="8">
              <Select
                :hideDetails="true"
                :items="selectOptions"
                :value="kind"
                label="Select type"
                @change="onChangeKind"
              />
            </v-col>
          </template>
          <v-col cols="4">
            <v-subheader>{{ display }}</v-subheader>
          </v-col>
          <v-col cols="8">
            <BaseInput
              :suffix="unit()"
              :value="editForm.value"
              fieldName="value"
              label="Enter your blood glucose values"
              @change="onFieldChange"
            />
          </v-col>
          <v-col cols="4">
            <v-subheader> Date</v-subheader>
          </v-col>
          <v-col cols="8">
            <DatePicker
              :dense="true"
              :height="47"
              :hideDetails="true"
              :placeholder="$t('general.inputs.date')"
              :value="editForm.date ? new Date(editForm.date) : ''"
              @setDate="onChangeDate"
            />
          </v-col>
          <v-col cols="4">
            <v-subheader>Time</v-subheader>
          </v-col>
          <v-col cols="8">
            <TimePicker :value="editForm.time" fieldName="time" @change="onChangeTime" />
          </v-col>
          <v-col cols="4">
            <v-subheader>Context</v-subheader>
          </v-col>
          <v-col cols="8">
            <BaseInput :value="editForm.note" fieldName="note" label="Enter Context" @change="onFieldChange" />
          </v-col>
        </v-row>
      </v-container>
    </Confirm>
  </div>
</template>
<script>
import { mapActions, mapState } from "pinia";

import { ObservationsApi } from "@/api/observations";
import Confirm from "@/components/shared/Confirm";
import DatePicker from "@/components/shared/DatePicker";
import TimePicker from "@/components/shared/TimePicker";
import BaseInput from "@/components/uikit/BaseInput";
import Select from "@/components/uikit/Select";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useAuthStore } from "@/pinia-store/auth";
import { useMonitoringStore } from "@/pinia-store/monitoring";
import { useObservationsStore } from "@/pinia-store/observations";
import { observationTypes } from "@/utils/observationTypes";

export default {
  name: "EditForm",
  components: {
    DatePicker,
    BaseInput,
    Confirm,
    Select,
    TimePicker,
  },
  props: {
    quickMonitoring: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapState(useAuthStore, ["uid"]),
    ...mapState(useMonitoringStore, ["orders"]),
    ...mapState(useObservationsStore, ["editForm", "editFormOpened", "patientId"]),
    isSubmitDisabled() {
      return Boolean(!this.date || !this.value || this.dateErrors.length || this.valueErrors.length);
    },
    selectOptions() {
      if (this.quickMonitoring) {
        return this.orders.map((order) => {
          const observation = observationTypes.find((ob) => ob.kind === order.kind);
          return { text: observation.display, value: observation.kind };
        });
      }
      return observationTypes.map((ob) => ({ text: ob.display, value: ob.kind }));
    },
    kind() {
      return this.editForm.kind;
    },
    display() {
      return observationTypes.find((ob) => ob.kind === this.editForm.kind)?.display;
    },
  },
  methods: {
    ...mapActions(useObservationsStore, ["toggleEditFormOpened"]),
    ...mapActions(useObservationsStore, ["changeEditFormField"]),
    unit() {
      return this.editForm?.kind?.length ? observationTypes.find((ob) => ob.kind === this.editForm.kind).unit : "";
    },
    onFieldChange(value, fieldName) {
      this.changeEditFormField({ field: fieldName, value });
    },
    onChangeTime(value) {
      this.changeEditFormField({ field: "time", value });
    },
    async onChangeKind(value) {
      await this.changeEditFormField({ field: "kind", value });
      await this.changeEditFormField({
        field: "display",
        value: value ? observationTypes.find((ob) => ob.kind === value).display : "",
      });
    },
    async onPatientSelect(patientId) {
      this.patientId = patientId;
      this.files = [];
      await this.setUid(patientId);
    },
    onChangeDate(value) {
      this.changeEditFormField({ field: "date", value });
    },
    async performStore() {
      try {
        this.loading = true;
        if (this.editForm.id)
          await ObservationsApi.update(this.editForm.id, {
            ...this.editForm,
            patientId: this.patientId,
          });
        else {
          const observationType = observationTypes.find((ob) => ob.kind === this.editForm.kind);

          if (observationType.sick && this.editForm.value < observationType.sick.max) {
            this.$emit("onSickModalOpen");
          } else {
            await ObservationsApi.create({ ...this.editForm, patientId: this.patientId });
          }
        }
        this.$emit("onSave");
        this.loading = false;
      } catch (err) {
        console.error(err);
        if (err && err.response && err.response.status === 409) {
          snackBarEventBus.$emit(snackBarEventName, {
            message: this.$t("general.errors.err"),
            type: "error",
          });
        }
      }
      this.$emit("onChange");
      this.loading = false;
    },
  },
};
</script>
<style lang="scss">
.monitoring-form {
  .card-title {
    font-weight: 500 !important;
    font-size: 25px !important;
    line-height: 31px !important;
    color: #25233a;
  }

  .v-card__title + .v-card__text {
    font-size: 14px;
    line-height: 17px;
    color: #33343d;
  }
}
</style>
